import { formatDate } from '@angular/common';
import { AttributeType } from '@interfaces/part-attributes.interface';

export enum PartAttributesSectionKey {
	C = 'C', // Classification
	CI = 'CI', // Commercial Information
	MTR = 'MTR', // Raw Material
	SOF = 'SOF', // Secondary Ops & Finish
	MTD = 'MTD', // Process & Tool Data
	PI = 'PI' // Part Information
}

export const GroupsOrder = [
	PartAttributesSectionKey.CI,
	PartAttributesSectionKey.C,
	PartAttributesSectionKey.MTR,
	PartAttributesSectionKey.PI,
	PartAttributesSectionKey.MTD,
	PartAttributesSectionKey.SOF
] as (string | PartAttributesSectionKey | null)[];

type ValueMapper = (value: string) => string | boolean | number | null;

export const IncomeValueMappers = {
	[AttributeType.int]: (value: string | null) => (value ? Number(value) : null),
	[AttributeType.number]: (value: string | null) => (value ? Number(value) : null),
	[AttributeType.bool]: (value: string) => value === 'true',
	[AttributeType.date]: (value: string) => (value ? formatDate(new Date(value), 'yyyy-MM-dd', 'en') : null),
	[AttributeType.list]: (value: string) => value,
	[AttributeType.listWithDynamicOptions]: (value: string) => value,
	[AttributeType.string]: (value: string) => value
} as Record<AttributeType, ValueMapper>;

export const OutcomeValueMappers = {
	[AttributeType.number]: (value: string | null) => (value ? parseFloat(value) : null),
	[AttributeType.int]: (value: string | null) => (value ? Number(value) : null),
	[AttributeType.bool]: (value: string) => (value ? 'true' : 'false'),
	[AttributeType.date]: (value: string | null) => (value ? new Date(value).toISOString() : null)
} as Record<AttributeType, ValueMapper>;

export const ModelConsumeOutcomeValueMappers = {
	[AttributeType.number]: (value: string | null) => (value ? parseFloat(value) : null),
	[AttributeType.int]: (value: string | null) => (value ? Number(value) : null),
	[AttributeType.bool]: (value: string | null) => (value === 'true' ? 'yes' : 'no'),
	[AttributeType.date]: (value: string | null) => (value ? new Date(value).toISOString() : null)
} as Record<AttributeType, ValueMapper>;

// Field is considered as 'filled' if condition below match
export const AttributeCompletionMap = {
	[AttributeType.bool]: (value: string | null) => value === 'true',
	[AttributeType.string]: (value: string | null) => value && value.trim() !== '',
	[AttributeType.int]: (value: number) => value !== null,
	[AttributeType.number]: (value: number) => value !== null,
	[AttributeType.date]: (value: unknown) => value !== null,
	[AttributeType.list]: (value: unknown) => value !== null,
	[AttributeType.listWithDynamicOptions]: (value: unknown) => value !== null
} as Record<AttributeType, (value: unknown) => boolean>;
