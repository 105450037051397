import { gql } from 'apollo-angular';

export const GetPartInfoByJpn = (fields: string[]) => gql`
	query AllParts($jpn: String!) {
		getPartsByJpn(jpn: $jpn) {
			${fields.join('\n')}
		}
	}
`;

export const GetPartInfoByCpn = (fields: string[]) => gql`
	query AllParts($cpn: String!) {
      getPartsByCpn(cpn: $cpn) {
			${fields.join('\n')}
		}
	}
`;

export const SearchByJpn = gql`
	query AllParts($jpn: String!) {
		getPartsByJpn(jpn: $jpn) {
			CI_JPN
		}
	}
`;

export const SearchByCpn = gql`
	query AllParts($cpn: String!) {
		getPartsByCpn(cpn: $cpn) {
			CI_Customer_PN
		}
	}
`;

export const GetRawPartWithAttributes = gql`
	query GetRawPartWithAttributes($partId: String!) {
		getRawPartWithAttributes(partId: $partId) {
			id
			jpn
			cpn
			drawing_number
			revision
			thumbnail
			attributes {
				attr_id
				value
			}
		}
	}
`;
