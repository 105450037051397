import { inject, Injectable } from '@angular/core';
import { RawPart, RawPartWithAttributes } from '@interfaces/raw-part.interface';
import { map, Observable } from 'rxjs';
import { PartNumberType } from '../enums/part-number-type.enum';
import * as RawPartsQueries from '../graphql/raw-parts.queries';
import { GetRawPartWithAttributes } from '../graphql/raw-parts.queries';
import { GqlServerService } from './gql-server.service';

@Injectable({
	providedIn: 'root'
})
export class RawPartsDataService {
	private readonly gql = inject(GqlServerService).main;

	getRawPartWithAttributes(partId: string): Observable<RawPartWithAttributes> {
		return this.gql
			.query<{ getRawPartWithAttributes: RawPartWithAttributes }>({
				query: GetRawPartWithAttributes,
				variables: {
					partId
				}
			})
			.pipe(map(res => res.data.getRawPartWithAttributes));
	}

	getPartInfoByPn(pn: string, fields: string[], partNumberType: PartNumberType) {
		if (partNumberType === PartNumberType.CPN) {
			return this.gql
				.query<{ getPartsByCpn: RawPart[] }>({
					query: RawPartsQueries.GetPartInfoByCpn(fields),
					variables: { cpn: pn }
				})
				.pipe(map(res => res.data.getPartsByCpn[0]));
		}

		return this.gql
			.query<{ getPartsByJpn: RawPart[] }>({
				query: RawPartsQueries.GetPartInfoByJpn(fields),
				variables: { jpn: pn }
			})
			.pipe(map(res => res.data.getPartsByJpn[0]));
	}

	searchByPartNumber(pn: string, partNumberType: PartNumberType) {
		if (partNumberType === PartNumberType.CPN) {
			return this.gql
				.query<{ getPartsByCpn: RawPart[] }>({
					query: RawPartsQueries.SearchByCpn,
					variables: { cpn: pn }
				})
				.pipe(map(res => res.data.getPartsByCpn.map((p: { CI_Customer_PN: string }) => p.CI_Customer_PN)));
		}

		return this.gql
			.query<{ getPartsByJpn: RawPart[] }>({
				query: RawPartsQueries.SearchByJpn,
				variables: { jpn: pn }
			})
			.pipe(map(res => res.data.getPartsByJpn.map((p: { CI_JPN: string }) => p.CI_JPN)));
	}
}
